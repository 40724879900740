<template>
  <base-authentication
    title="Réinitialisation de votre mot de passe"
    subtitle="Afin de réinitialiser votre mot de passe, nous vous invitons à renseigner le nouveau ci-dessous"
  >
    <form @submit.prevent="changePassword">
      <v-row class="my-6">
        <v-col>
          <form-fields-password
            v-model="password"
            placeholder="Nouveau mot de passe"
            label="Nouveau mot de passe"
            :rules="passwordRules"
          />
          <form-fields-password
            v-model="confirmPassword"
            placeholder="Confirmer le nouveau mot de passe"
            label="Confirmer le nouveau mot de passe"
            :rules="confirmRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          cols="12"
          class="d-flex flex-column pt-0"
        >
          <div class="d-flex mb-6 forgot-password__caption align-center">
            <span class="font-italic subtitle-2">
              Vous avez déjà un login et un mot de passe ?
            </span>
            <router-link to="/login">
              <span class="cassiopee-purple--text mx-3 pr-9 font-italic subtitle-2">S'identifier</span>
            </router-link>
          </div>
          <base-button 
            right
            x-small
            icon="$arrowRight"
            type="submit"
          >
            Poursuivre
          </base-button>
        </v-col>
      </v-row>
    </form>
  </base-authentication>
</template>

<script>
import BaseAuthentication from '@/components/BaseAuthentication.vue'
import FormFieldsPassword from '@/components/FormFieldsPassword.vue'
import BaseButton from '@/components/BaseButton.vue'
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: "ResetPassword",
  components: {
    BaseAuthentication,
    FormFieldsPassword,
    BaseButton
  },
  data() {
    return {
      confirmPassword: '',
    };
  },
  computed: {
    ...mapFields('authentication', ['passwordResetForm.password']),
    passwordRules() {
      return [
        this.password?.length >= 6 || 'Votre mot de passe doit faire au moins 6 caractères',
      ];
    },
    confirmRules() {
      return [
        (this.password === this.confirmPassword) || 'La confirmation ne correspond pas au mot de passe entré',
      ]
    },
  },
  methods: {
    ...mapActions('authentication', ['updatePassword']),
    ...mapMutations(['setAlert']),
    changePassword() {
      const { token } = this.$route.query;
      this.updatePassword(token).then(() => {
        this.setAlert({
          show: true,
          text: 'Votre mot de passe a été changé. Vous pouvez dès maintenant essayer de vous connecter avec celui-ci.',
        });
        this.$router.push('/login');
      }).catch((error) => {
        if (error.response.data?.errors?.reset_password_token) {
          this.setAlert({
            show: true,
            text: 'Le lien de réinitialisation du mot de passe est expiré ou incorrect. Veuillez réessayer.',
          });
        } else {
          this.setAlert({
            show: true,
            text: "Une erreur s'est produite",
          });
        }
      });
    },
  },
}
</script>